

<template>
  <div class="Attend_2">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">考勤组设置</el-menu-item>
        <el-menu-item index="2">班次设置</el-menu-item>
        <el-menu-item index="3">假期设置</el-menu-item>
        <el-menu-item index="4">考勤数据</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>
    <div class="Tables">
       <div class="Tab_T" @click="Xinjian()" v-if="TextMoMo('attendance:class:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">添加班次</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div>
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handhange">
            <el-table-column
              type="selection"
              width="55"
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="65"
              align="center"></el-table-column>
            <el-table-column align="center" prop="title" label="班次名称" width="300"></el-table-column>
            <el-table-column align="center" prop="leader.realname" label="班次负责人" width="180"></el-table-column>
            <el-table-column align="center" prop="section" label="考勤时间" ></el-table-column>
            <el-table-column label="操作" align="center" >
              <template #default="scope" >
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(2, scope.row)" v-if="TextMoMo('attendance:class:read')">查看</el-button>
                    <el-button type="text" @click="Handlbchak(2, scope.row)" v-if="!TextMoMo('attendance:class:read')">查看</el-button>
                  </div>
                  <div class="el_butto" >
                    <el-button type="text" @click="Handlbchak(1, scope.row)" v-if="TextMoMo('attendance:class:edit')">编辑</el-button>
                  </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text" v-if="TextMoMo('attendance:class:del')"
                    class="red"
                    @click="HanDel(scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handhange1"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              layout="total, prev, pager,next, jumper"
              :total="total1">
            </el-pagination>
          </div>
        </div>

    </div>


    <div ref="rightPanel" v-if="show2" class="rightPanel-container show">
      <div class="rightPanel-background"/>
      <div style="height:1100px;width: 576px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel">
            <div class="Rig_1" v-if="XX == 1">新增班次<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_1" v-if="XX == 2">编辑班次<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_A">
              <div class="Rig_D dsddd">
                <div class="Rig_Ddd" style="float:none;margin-bottom:8px" >班次名称：</div>
                <div class="Rtty">
                  <el-input v-model="List_G.title" placeholder="请输入班次名称" class="El_ii" style="width: 95%;"></el-input>
                </div>
              </div>
              <div class="Rig_D dsddd">
                  <div class="Rig_Ddd" style="margin-bottom:12px">考勤负责人：</div>
                  <el-select v-model="List_G.leader_id" placeholder="请选择考勤负责人" style="width: 95%;">
                      <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                  </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:none;">打卡时段：</div>
                <div style="float:left;margin-top:10px">
                    <el-radio v-model="List_G.num" :label="1" @change="Radio">1天1次上下班</el-radio>
                    <el-radio v-model="List_G.num" :label="2" @change="Radio">1天2次上下班</el-radio>
                    <el-radio v-model="List_G.num" :label="3" @change="Radio">1天3次上下班</el-radio>
                </div>
              </div>
              <div class="Rig_D" style="margin-bottom:0" v-for="(item, index) in SectionsList" :key="item" >
                <div class="Rig_Ddd" style="float:none">第{{index + 1}}次</div>
                <div class="sdsdsdda" >
                      <el-time-select placeholder="上班时间" v-model="item.start" style="width: 100%;"></el-time-select>
                      <span class="line" :span="2" style="margin:5px 8px 0 8px;position: relative;">至</span>
                      <el-time-select placeholder="下班时间" v-model="item.end" style="width: 100%;"></el-time-select>
                </div>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="width:70px;margin-right:0;">是否休息： </div>
                <div>
                    <el-checkbox v-model="List_G.is_rest" :true-label="1" :false-label="0"></el-checkbox>
                </div>
              </div> 
              <div class="Rig_D" style="margin-bottom:0" v-if="List_G.is_rest == 1">
                <div class="Rig_Ddd" style="float:none">休息时间</div>
                <div style="float:left">
                  <div class="sdsdsdda">
                    <el-time-select placeholder="上班时间" v-model="List_G.rest_starttime" style="width: 100%;"></el-time-select>
                    <span class="line" :span="2" style="margin:0 8px;position: relative;">至</span>
                    <el-time-select placeholder="下班时间" v-model="List_G.rest_endtime" style="width: 100%;"></el-time-select>
                  </div>

                </div>
              </div>
              <div class="Rig_D Rig_Y Tyyyyty">
                <div class="Rig_Ddd">更多设置</div>
                  <div style="float:left;margin-top:10px">                                  
                    <!-- <el-checkbox checked style="margin:10px 0 5px 0">晚到、提前几分钟不记为异常</el-checkbox> -->
                    <div class="Righh">上班最多可晚到：<el-input v-model="List_G.late_time" placeholder="10"></el-input>&nbsp;&nbsp;晚到10分钟内不算迟到</div>
                    <div class="Righh">下班最多可提前：<el-input v-model="List_G.early_time" placeholder="10"></el-input>&nbsp;&nbsp;提前5分钟内不算早退</div>
                    <!-- <el-checkbox label="" style="margin:10px 0 5px 0">晚到、提前几分钟不记为缺卡</el-checkbox> -->
                    <div class="Righh">上班最多可晚到：<el-input v-model="List_G.miss_am_time" placeholder="10"></el-input>&nbsp;&nbsp;晚到30分钟内不算缺卡半日</div>
                    <div class="Righh">下班最多可提前：<el-input v-model="List_G.miss_pm_time" placeholder="10"></el-input>&nbsp;&nbsp;提前30分钟内不算缺卡半日</div>
                    <!-- <el-checkbox label="" style="margin:10px 0 5px 0">允许提前、延后打卡</el-checkbox> -->
                    <div class="Righh">打卡最多可提前：<el-input v-model="List_G.advance_time" placeholder="10"></el-input>&nbsp;&nbsp;可以提前100分钟打卡</div>
                    <div class="Righh">打卡最多可延后：<el-input v-model="List_G.daily_time" placeholder="10"></el-input>&nbsp;&nbsp;可以延后30分钟打卡</div>
                  </div>
              </div> 
              <div class="BCSZ" style="margin-right:24px" v-if="XX == 1" @click="BTN_tj">确认添加</div>
              <div class="BCSZ" style="margin-right:24px" v-if="XX == 2" @click="BTN_tj2">保存设置</div>
              <div class="BCSZ" style="margin-right:24px;background:#fff;color:#606266;border:1px solid rgb(217, 217, 217) " @click="show2 = false">取消</div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <div ref="rightPanel" v-if="show22" class="rightPanel-container show">
      <div class="rightPanel-background"/>
      <div style="height:1100px;width: 576px;position: fixed;top: 0;right: 0;z-index:999;" >
        <el-scrollbar style="height:100%">
          <div class="rightPanel">
            <div class="Rig_1">查看班次<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
            <div class="Rig_A">
              <div class="Rig_D dsddd">
                <div class="Rig_Ddd" style="float:none;margin-bottom:8px" >班次名称：</div>
                <div class="Rtty">
                  <el-input v-model="List_G.title" placeholder="请输入班次名称" class="El_ii" disabled style="width: 95%;"></el-input>
                </div>
              </div>
              <div class="Rig_D dsddd">
                  <div class="Rig_Ddd" style="margin-bottom:12px">考勤负责人：</div>
                  <el-select v-model="List_G.leader_id" placeholder="请选择考勤负责人" disabled style="width: 95%;">
                      <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                  </el-select>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="float:none;">打卡时段：</div>
                <div style="float:left;margin-top:10px">
                    <el-radio v-model="List_G.num" :label="1" @change="Radio" disabled>1天1次上下班</el-radio>
                    <el-radio v-model="List_G.num" :label="2" @change="Radio" disabled>1天2次上下班</el-radio>
                    <el-radio v-model="List_G.num" :label="3" @change="Radio" disabled>1天3次上下班</el-radio>
                </div>
              </div>
              <div class="Rig_D" style="margin-bottom:0" v-for="(item, index) in SectionsList" :key="item" >
                <div class="Rig_Ddd" style="float:none">第{{index + 1}}次</div>
                <div class="sdsdsdda" >
                      <el-time-select placeholder="上班时间" v-model="item.start" style="width: 100%;" disabled></el-time-select>
                      <span class="line" :span="2" style="margin:5px 8px 0 8px;position: relative;">至</span>
                      <el-time-select placeholder="下班时间" v-model="item.end" style="width: 100%;" disabled></el-time-select>
                </div>
              </div>
              <div class="Rig_D">
                <div class="Rig_Ddd" style="width:70px;margin-right:0;">是否休息： </div>
                <div>
                    <el-checkbox v-model="List_G.is_rest" :true-label="1" :false-label="0" disabled></el-checkbox>
                </div>
              </div> 
              <div class="Rig_D" style="margin-bottom:0" v-if="List_G.is_rest == 1">
                <div class="Rig_Ddd" style="float:none">休息时间</div>
                <div style="float:left;">
                  <div class="sdsdsdda">
                    <el-time-select placeholder="上班时间" v-model="List_G.rest_starttime" style="width: 100%;" disabled></el-time-select>
                    <span class="line" :span="2" style="margin:0 8px;position: relative;">至</span>
                    <el-time-select placeholder="下班时间" v-model="List_G.rest_endtime" style="width: 100%;" disabled></el-time-select>
                  </div>

                </div>
              </div>
              <div class="Rig_D Rig_Y Tyyyyty">
                <div class="Rig_Ddd">更多设置</div>
                  <div style="float:left;margin-top:10px">                                  
                    <!-- <el-checkbox checked style="margin:10px 0 5px 0">晚到、提前几分钟不记为异常</el-checkbox> -->
                    <div class="Righh">上班最多可晚到：<el-input v-model="List_G.late_time" placeholder="10" ></el-input>&nbsp;&nbsp;晚到10分钟内不算迟到</div>
                    <div class="Righh">下班最多可提前：<el-input v-model="List_G.early_time" placeholder="10"></el-input>&nbsp;&nbsp;提前5分钟内不算早退</div>
                    <!-- <el-checkbox label="" style="margin:10px 0 5px 0">晚到、提前几分钟不记为缺卡</el-checkbox> -->
                    <div class="Righh">上班最多可晚到：<el-input v-model="List_G.miss_am_time" placeholder="10"></el-input>&nbsp;&nbsp;晚到30分钟内不算缺卡半日</div>
                    <div class="Righh">下班最多可提前：<el-input v-model="List_G.miss_pm_time" placeholder="10"></el-input>&nbsp;&nbsp;提前30分钟内不算缺卡半日</div>
                    <!-- <el-checkbox label="" style="margin:10px 0 5px 0">允许提前、延后打卡</el-checkbox> -->
                    <div class="Righh">打卡最多可提前：<el-input v-model="List_G.advance_time" placeholder="10"></el-input>&nbsp;&nbsp;可以提前100分钟打卡</div>
                    <div class="Righh">打卡最多可延后：<el-input v-model="List_G.daily_time" placeholder="10"></el-input>&nbsp;&nbsp;可以延后30分钟打卡</div>
                  </div>
              </div> 
              <div class="BCSZ" style="margin-right:24px;background:#fff;color:#606266;border:1px solid rgb(217, 217, 217) " @click="show22 = false">取消</div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      show22:false,
      input_seach:'',
      activeIndex: "2",  
      Fror: {
        ssid:'',   
        bssid:'', 
      },
      radio:'1',
      show1:false,
      show2:false,
      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      editVisible2: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      Handlbchak_id:0,
      WifiList:[],  
      List_G: {

      },
      LocationList:[],
      StaFFList:[],      //全部员工列表
      DMent:[],
      SectionsList:[],
      XX:0 ,                      //1新建  2编辑
    };
  },
  mounted() {
    var that = this
      axios({
        method: 'post',
        url: '/admin/Attendance/attenclass/index', 
        data: {
          page: 1,
          offset:0,
          limit:10,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    axios.get('/admin/ajax/staffList',{                   // 全部员工列表
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            console.log(res.data.data)
              that.StaFFList = res.data.data
          }
    })   
    axios.get('/admin/company/department/index',{
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DMent = res.data.data.rows
        }
    })
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Radio(e) {
      var that = this
      that.SectionsList = []
      var arr = that.SectionsList
      var acc = {start:'',end:''}
      if (e == 1) {
        arr.push(acc)
      } else if (e == 2) {
        arr.push(acc)
        arr.push(acc)
      } else if (e == 3) {
        arr.push(acc)
        arr.push(acc)
        arr.push(acc)
      }
    },
    handl_Del(e,index, data) {
      var that = this
      if (e == 1) {
      } else if (e == 2) {
        var LnList = that.WifiList
        LnList.splice(index,1)
      }
    },
    saveEdit2(){                       //确认添加位置
      var that = this
      var arr = that.WifiList
        axios({
          method: 'post',
          url: '/admin/Attendance/wifi/add',
          data: {
            row: that.Fror
          },
        }).then(function (res) {
            console.log()
            arr.push(res.data.data)
            that.WifiList = arr
        }) 
      that.editVisible2 = false
    },
    Tjwei(e) {                         //添加位置
      var that = this
      if (e == 1) {
        that.editVisible = true
      } else if (e ==2) {
        that.editVisible2 = true
      }
    },
    Xinjian() {
      var that = this
      that.XX = 1
      that.show2 = true
      that.List_G = {
        num:1
      },
      that.SectionsList = [
        {start:'',end:''}
      ]
      that.Litaffids = []
    },
    BTN_tj() {                        //考勤组提交 保存设置
      var that = this
      that.List_G.sections = that.SectionsList
        axios({
          method: 'post',
          url: '/admin/Attendance/attenclass/add',
          data: {
            row: that.List_G
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
               that.$message.success(res.data.msg);
                that.show2 = false
                axios({
                  method: 'post',
                  url: '/admin/Attendance/attenclass/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   
            }
        }) 
    },
    BTN_tj2() {                        //考勤组提交 编辑
      var that = this
      that.List_G.sections = that.SectionsList
          axios({
          method: 'put',
          url: '/admin/Attendance/attenclass/edit',
          data: {
            id: that.Handlbchak_id,
            row: that.List_G
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
               that.$message.success(res.data.msg);
                that.show2 = false
                axios({
                  method: 'post',
                  url: '/admin/Attendance/attenclass/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   

            }

        }) 
    },
    handhange1(val) {
      var that = this
        axios({
          method: 'post',
          url: '/admin/Attendance/attenclass/index', 
          data: {
            page: val,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })   
    },
    Close() {
      this.show1 = false
      this.show22 = false
      this.show2 = false
    },
    Handlbchak(e) {                          //编辑
      var that = this
      that.Handlbchak_id = e.id

      if (e == 1) {
        that.XX = 2
        that.show2 = true
      } else if (e == 2) {
        that.show22 = true
      }

      axios.get('/admin/Attendance/attenclass/read',{   // 模块
        params:{
            id:e.id
        }
        }).then(function (res) {
            that.List_G = res.data.data.row
            that.SectionsList = res.data.data.sectionsList
      })
    },
    handleSelect(e) {
      if (e == 1) {
        this.$router.push('/Manage/Attend');
      }else if (e == 2) {
        this.$router.push('/Manage/Attend_2');
      } else if (e == 3) {
        this.$router.push('/Manage/Attend_3');
      }
    },

    // 触发搜索按钮
    inpu_sech() {
        var that = this
        axios({
          method: 'post',
          url: '/admin/Attendance/attenclass/index', 
          data: {
            page: 1,
            offset:0,
            limit:10,
            filter: {
              keyword: that.input_seach
            }
          },
        }).then(function (res) {
          that.tableData = res.data.data.rows
          that.total1 = res.data.data.total
        })          
    },
    // 删除操作
    HanDel(e) {
        // 二次确认删除
        var that = this
        var arr = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
            axios.delete('/admin/Attendance/attenclass/del',{ 
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({
                  method: 'post',
                  url: '/admin/Attendance/attenclass/index', 
                  data: {
                    page: 1,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                  that.tableData = res.data.data.rows
                  that.total1 = res.data.data.total
                })   
              }
          })   
        })
    }

  },
};
</script>


<style scoped> 
.Attend_2 #app {
  width: 100%;
  height: 300px;
  background: #d9d9d9;
}
.Attend_2 .Tyyyyty .el-input__inner, .Attend_2 .Tyyyyty .el-textarea__inner{
  width: 70px;
}
.Attend_2 .Tyyyyty .el-input {
    width: 70px;
}
.Tyyyyty .Righh {
  margin-bottom:10PX ;
  font-size: 14px;
}
.Attend_2 .sdsdsdda {
  margin: 20px 0;
  overflow: hidden;
}
.Attend_2 .sdsdsdda .el-input--prefix .el-input__inner ,.Attend_2 .sdsdsdda .el-select{
  width: 234px!important; 
}
.Attend_2 .sdsdsdda .el-col-11 {
    float: left;
}
.Attend_2 .sdsdsdda .el-input__suffix, .Attend_2 .sdsdsdda .el-input__icon {
  float: left;
}
.Attend_2 .sdsdsdda .el-icon-arrow-up {
  display: none;
}
</style>
